import { Nilable } from '@wistia/type-guards';
import { h, JSX } from 'preact';
import { HardWall } from './HardWall.tsx';

type HardWallWithLoginPromptProps = {
  backgroundColor: string;
  loginUrl: string;
  logoUrl?: Nilable<string>;
  playerColor: string;
};

export const HardWallWithLoginPrompt = ({
  backgroundColor,
  loginUrl,
  logoUrl,
  playerColor,
}: HardWallWithLoginPromptProps): JSX.Element => {
  return (
    <HardWall backgroundColor={backgroundColor} logoUrl={logoUrl} playerColor={playerColor}>
      <div className="login-prompt-container">
        <div>You must login to access this channel</div>
        <a className="login-prompt-link" href={loginUrl}>
          Login
        </a>
      </div>
    </HardWall>
  );
};

import { isArray, isNil, isNotNil } from '@wistia/type-guards';
import { cdnFastWistiaComHost, cdnFastWistiaNetHost, VALID_FASTLY_HOSTS } from './hosts.js';
import { Url } from './url.js';

type SpeedDemonKeyType = 'carousel' | 'channel';

export const speedDemonKey = (hashedId: string, type: SpeedDemonKeyType = 'channel'): string => {
  if (type === 'carousel') {
    return `wcarouseljsonp-${hashedId}`;
  }

  return `wchanneljsonp-${hashedId}`;
};

export const speedDemonScripts = (hashedId: string): HTMLScriptElement[] | undefined => {
  const allScriptElements = document.querySelectorAll('script[src]');
  if (isNil(allScriptElements)) {
    return undefined;
  }

  const allScriptsAsArray = Array.from(allScriptElements).slice() as HTMLScriptElement[];

  const validHosts = VALID_FASTLY_HOSTS.map((hostName) => `fast.${hostName}`).concat([
    cdnFastWistiaComHost(),
    cdnFastWistiaNetHost(),
  ]);

  return allScriptsAsArray.filter((script) => {
    /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
    const url = new Url(script.src);
    const matchesHost = validHosts.some((hostName) => url.host === hostName);
    const matchesPath = url.rawPath === `/embed/channel/project/${hashedId}.js`;
    return matchesHost && matchesPath;
    /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
  });
};

export const speedDemonScriptExists = (
  hashedId: string,
  type: SpeedDemonKeyType = 'channel',
): boolean => {
  const key = speedDemonKey(hashedId, type);
  if (isNil(key)) {
    return false;
  }
  if (isNotNil(window[key])) {
    return true;
  }

  const scriptElements = speedDemonScripts(hashedId);
  return isArray(scriptElements) && scriptElements.length > 0;
};

import { assign, pick } from 'utilities/obj.js';
import { Url, objectToQueryParams, queryParamsToObject } from 'utilities/url.js';
import { Wistia } from '../../wistia_namespace.ts';

const QUERY_PARAMS_STRATEGY = 'query-params';
const ANCHOR_PARAMS_STRATEGY = 'anchor-params';
const CUSTOM_STRATEGY = 'custom';
const PATH_STRATEGY = 'path';

const GALLERY_PARAMS = [
  'wchannelid',
  'wgalleryid',
  'wmediaid',
  'wseriesid',
  'wvideoid',
  'wepisodeid',
];

const translateWgalleryToWchannel = (routeData) => {
  // Translate wgalleryid to wchannelid so we can deprecate it.
  if (routeData.wgalleryid && !routeData.wchannelid) {
    routeData.wchannelid = routeData.wgalleryid;
    delete routeData.wgalleryid;
  }
  return routeData;
};

const translateWvideoToWmedia = (routeData) => {
  // Translate wvideoid to wmediaid so we can deprecate it.
  if (routeData.wvideoid && !routeData.wmediaid) {
    routeData.wmediaid = routeData.wvideoid;
    delete routeData.wvideoid;
  }
  return routeData;
};

const translateParams = (routeData) => {
  return translateWgalleryToWchannel(translateWvideoToWmedia(routeData));
};

export const getRouteDataFromAnchor = (uri) => {
  const url = new Url(uri);
  const params = queryParamsToObject(url.anchor);
  const relevantParams = pick(params, GALLERY_PARAMS);
  return translateParams(relevantParams);
};

export const getRouteDataFromPath = (uri) => {
  const url = new Url(uri);

  const pathStr = url.path.join('/');

  let result;

  const galleryVideoMatches = pathStr.match(GALLERY_VIDEO_PATH_REGEX);
  const galleryEpisodeMatches = pathStr.match(GALLERY_EPISODE_PATH_REGEX);
  const gallerySectionMatches = pathStr.match(GALLERY_SECTION_PATH_REGEX);
  const gallerySeriesMatches = pathStr.match(GALLERY_SERIES_PATH_REGEX);
  const galleryMatches = pathStr.match(GALLERY_PATH_REGEX);

  if (galleryVideoMatches) {
    result = {
      wchannelid: galleryVideoMatches[1],
      wmediaid: galleryVideoMatches[2],
    };
  } else if (galleryEpisodeMatches) {
    result = {
      wchannelid: galleryEpisodeMatches[1],
      wepisodeid: galleryEpisodeMatches[2],
    };
  } else if (gallerySectionMatches) {
    result = {
      wchannelid: gallerySectionMatches[1],
      wseriesid: gallerySectionMatches[2],
      wsectionid: gallerySectionMatches[3],
    };
  } else if (gallerySeriesMatches) {
    result = {
      wchannelid: gallerySeriesMatches[1],
      wseriesid: gallerySeriesMatches[2],
    };
  } else if (galleryMatches) {
    result = {
      wchannelid: galleryMatches[1],
    };
  } else {
    result = {};
  }

  return result;
};

export const getRouteDataFromQueryParams = (uri) => {
  const url = new Url(uri);
  const relevantParams = pick(url.params, GALLERY_PARAMS);
  return translateParams(relevantParams);
};

export const getRouteDataFromUri = (uri, options = {}) => {
  if (options.routeStrategy === QUERY_PARAMS_STRATEGY) {
    return getRouteDataFromQueryParams(uri);
  }

  if (options.routeStrategy === ANCHOR_PARAMS_STRATEGY) {
    return getRouteDataFromAnchor(uri);
  }

  if (options.routeStrategy === PATH_STRATEGY) {
    return getRouteDataFromPath(uri);
  }

  if (options.routeStrategy === CUSTOM_STRATEGY) {
    return options.urlToRouteData(uri) || {};
  }

  return getRouteDataFromQueryParams(uri);
};

export const setRouteDataAsAnchorParams = (baseUrl, anchorParams) => {
  const url = new Url(removeAnchorParamsFromUri(baseUrl));
  const params = queryParamsToObject(url.anchor);
  assign(params, anchorParams);
  url.anchor = objectToQueryParams(params) || undefined;
  return url.absolute();
};

export const setRouteDataAsPath = (baseUrl, routeData) => {
  const url = new Url(removePathFromUri(baseUrl));
  const { wchannelid, wseriesid, wsectionid, wmediaid, wepisodeid } = routeData;

  const parts = [];
  if (wchannelid) {
    parts.push(`channel-${wchannelid}`);
  }

  if (!wmediaid && !wepisodeid) {
    if (wseriesid) {
      parts.push(`series-${wseriesid}`);
    }

    if (wsectionid) {
      parts.push(`section-${wsectionid}`);
    }
  }

  if (wmediaid) {
    parts.push(`video-${wmediaid}`);
  }

  if (wepisodeid) {
    parts.push(`episode-${wepisodeid}`);
  }

  url.setPath(url.path.concat(parts).join('/'));

  return url.absolute();
};

export const setRouteDataAsQueryParams = (baseUrl, queryParams) => {
  const url = new Url(removeQueryParamsFromUri(baseUrl));
  assign(url.params, queryParams);
  return url.absolute();
};

export const setRouteDataOnUri = (baseUrl, routeData, options = {}) => {
  if (options.routeStrategy === QUERY_PARAMS_STRATEGY) {
    return setRouteDataAsQueryParams(baseUrl, routeData);
  }

  if (options.routeStrategy === ANCHOR_PARAMS_STRATEGY) {
    return setRouteDataAsAnchorParams(baseUrl, routeData);
  }

  if (options.routeStrategy === PATH_STRATEGY) {
    return setRouteDataAsPath(baseUrl, routeData);
  }

  if (options.routeStrategy === CUSTOM_STRATEGY) {
    return options.routeDataToUrl(baseUrl, routeData) || location.href;
  }

  return setRouteDataAsQueryParams(baseUrl, routeData);
};

export const removeAnchorParamsFromUri = (uri) => {
  const url = new Url(uri);
  const params = queryParamsToObject(url.anchor);
  deleteGalleryParamsFromObject(params);
  url.anchor = objectToQueryParams(params) || undefined;
  return url.absolute();
};

export const removeQueryParamsFromUri = (uri) => {
  const url = new Url(uri);
  deleteGalleryParamsFromObject(url.params);
  return url.absolute();
};

const GALLERY_PATH_REGEX = /(?:gallery|channel)-(\w+)(\/[\w-]+)?$/;
const GALLERY_VIDEO_PATH_REGEX = /(?:gallery|channel)-(\w+)\/video-(\w+)(\/[\w-]+)?$/;
const GALLERY_EPISODE_PATH_REGEX = /(?:gallery|channel)-(\w+)\/episode-(\w+)(\/[\w-]+)?$/;
const GALLERY_SERIES_PATH_REGEX = /(?:gallery|channel)-(\w+)\/series-(\w+)(\/[\w-]+)?$/;
const GALLERY_SECTION_PATH_REGEX =
  /(?:gallery|channel)-(\w+)\/series-(\w+)\/section-(\w+)(\/[\w-]+)?$/;

const VALID_PATH_FORMS = [
  GALLERY_SECTION_PATH_REGEX,
  GALLERY_SERIES_PATH_REGEX,
  GALLERY_VIDEO_PATH_REGEX,
  GALLERY_EPISODE_PATH_REGEX,
  GALLERY_PATH_REGEX,
];
export const removePathFromUri = (uri) => {
  const url = new Url(uri);

  const pathStr = url.path.join('/');
  const mostSpecificMatchingRegexp = VALID_PATH_FORMS.filter((regexp) => regexp.test(pathStr))[0];

  if (mostSpecificMatchingRegexp) {
    url.setPath(pathStr.replace(mostSpecificMatchingRegexp, ''));
  }

  return url.absolute();
};

const deleteGalleryParamsFromObject = (obj) => {
  GALLERY_PARAMS.forEach((p) => delete obj[p]);
};

if (!Wistia._canonicalUrl) {
  const canonicalLink = document.querySelector('link[rel=canonical]');
  const canonicalUrl = canonicalLink && canonicalLink.getAttribute('href');

  if (canonicalUrl) {
    // The host page has supplied us with a canonical URL. Let's use that!
    Wistia._canonicalUrl = canonicalUrl;
  } else {
    // If no canonical URL is supplied, assume it's the current page with no
    // query params. This might not be correct, but if it isn't, we can tell them
    // to set link[rel=canonical]!
    const url = new Url(location.href);
    url.params = {};
    Wistia._canonicalUrl = url.absolute();
  }
}

export const originalCanonicalUrl = () => {
  return Wistia._canonicalUrl;
};

export const removeTrackingParams = (urlStr) => {
  const url = new Url(urlStr);
  Object.keys(url.params).forEach((k) => {
    // utm_ = standard tracking params. hsa_ = hubspot. _hs also hubspot.
    if (/^(utm_|hsa_|_hs)/.test(k)) {
      delete url.params[k];
    }
  });
  // the unprefixed "source" is a common tracking param too.
  delete url.params.source;
  return url.absolute();
};

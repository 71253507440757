import { ComponentChildren, Fragment, h, JSX, render } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { isNil, isNonEmptyString, Nilable } from '@wistia/type-guards';
import { interFontFamily } from '../../../../utilities/interFontFamily.js';
import { Color } from '../../../../utilities/color.js';
import { dynamicImport } from '../../../../utilities/dynamicImport.ts';
import {
  generateHardWallColorPalette,
  getHardWallColorPaletteAsCssVars,
} from './hardWallColorPalette.ts';
import { HARD_WALL_STYLES } from './HardWallStyles.tsx';
import { getHardWallBorderRadiusAsCssVars } from './hardWallBorderRadius.ts';

type HardWallProps = {
  backgroundColor: string;
  children?: ComponentChildren;
  containerBorderRadius?: Nilable<number>;
  inlineMessage?: Nilable<string>;
  logoUrl?: Nilable<string>;
  playerColor: string;
};

export const HardWall = ({
  backgroundColor,
  children,
  containerBorderRadius,
  inlineMessage,
  logoUrl,
  playerColor,
}: HardWallProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const colorPalette = generateHardWallColorPalette(
    new Color(backgroundColor),
    new Color(playerColor),
  );

  useEffect(() => {
    void dynamicImport('assets/external/interFontFace.js');
    containerRef.current?.attachShadow({ mode: 'open' });
    // We just attached the shadow DOM, so it's not null.
    // But typescript thinks it might be, so we'll include this null-check.
    if (isNil(containerRef.current?.shadowRoot)) {
      return;
    }
    render(
      <Fragment>
        <style id="color-palette-variables">
          {`${getHardWallColorPaletteAsCssVars(colorPalette)};`}
          {`${getHardWallBorderRadiusAsCssVars(containerBorderRadius)};`}
        </style>
        <style>
          {`
            :host {
              * {
                box-sizing: border-box;
                font-family: ${interFontFamily}
              }
            }
            ${HARD_WALL_STYLES}
          `}
        </style>
        <div
          className={`hard-wall-container ${isNonEmptyString(logoUrl) && 'has-inline-logo'}`}
          data-testid="hard-wall-container"
        >
          {isNonEmptyString(logoUrl) && (
            <div className="logo-container">
              <img src={logoUrl} alt="logo" />
            </div>
          )}
          {isNonEmptyString(inlineMessage) && (
            <div className="inline-message-container">{inlineMessage}</div>
          )}
          {children}
        </div>
      </Fragment>,
      containerRef.current.shadowRoot,
    );
  }, []);

  useEffect(() => {
    const updatedColorPalette = generateHardWallColorPalette(
      new Color(backgroundColor),
      new Color(playerColor),
    );

    const cssVariablesStyleBlock = containerRef.current?.shadowRoot?.querySelector(
      '#color-palette-variables',
    );
    if (isNil(cssVariablesStyleBlock)) {
      return;
    }
    cssVariablesStyleBlock.innerHTML = `
      :host {
        * {
          box-sizing: border-box;
          font-family: ${interFontFamily}
        }
        ${getHardWallColorPaletteAsCssVars(updatedColorPalette)};
        ${getHardWallBorderRadiusAsCssVars(containerBorderRadius)};
      }
    `;
  }, [backgroundColor, playerColor, containerBorderRadius]);

  return (
    <Fragment>
      <div ref={containerRef} id="wistia-hard-wall"></div>
    </Fragment>
  );
};

import { cachedDetect } from 'utilities/detect.js';
import { h, render, Component } from 'preact';
import { unescapeHtml } from 'utilities/core.js';
import { assign } from 'utilities/assign.js';
import { batchFetchData } from 'utilities/batchFetchMediaData.js';
import { anyValuesChanged } from 'utilities/any-values-changed.js';
import { prefetchEngineAndPlugins } from 'utilities/prefetching.js';
import { getShortDescription } from 'utilities/getShortDescription.js';
import { Color } from 'utilities/color.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { fetchMediaData } from 'utilities/fetchMediaData.ts';
import { Wistia } from '../../../wistia_namespace.ts';
import { secondsConverter } from '../../../utilities/duration.ts';
import headerFontSizeGw from '../headerFontSizeGw.js';
import { marginWidth } from './gridMath.js';
import { setRouteDataOnUri } from '../uriStateTransformers.js';
import { RawHTMLStub } from '../../shared/RawHTMLStub.jsx';

const detect = cachedDetect();

const DESCRIPTION_OPACITY_TRANSITION_DURATION = 1000;
const SCALE_TRANSITION_DURATION = 100;
const HOVER_TRANSFORM_TRANSITION_DELAY = SCALE_TRANSITION_DURATION;
const HOVER_TRANSFORM_TRANSITION_DURATION = 5000;
const INCREMENTAL_DELAY = 240;

const formatDuration = (totalSeconds) => {
  const { hours, minutes } = secondsConverter(totalSeconds, 'hms');

  if (totalSeconds < 45) {
    return `${Math.round(totalSeconds)} SEC`;
  }

  if (hours) {
    return `${hours} HR ${minutes} MIN`;
  }

  return `${Math.round(totalSeconds / 60)} MIN`;
};

class GridMediaCard extends Component {
  constructor(props) {
    super(props);
    const { color, name } = props;
    this.unescapedName = unescapeHtml(name);
    this.unbinds = [];
    const { galleryData, hashedId, routeStrategyOptions } = this.props;
    const routeData = {
      wchannelid: galleryData.hashedId,
      wmediaid: hashedId,
    };
    this.videoUrl = setRouteDataOnUri(location.href, routeData, routeStrategyOptions);
    this.state = {
      color,
      isHoveringOrFocusing: false,
      isScaledUp: false,
      shortDescription: null,
    };
  }

  get playerColor() {
    const { color, galleryEmbedOptions } = this.props;
    return this.state.color || color || galleryEmbedOptions.color;
  }

  maybeInitPopover() {
    const { suppressPopover } = this.props;

    if (!this.didInit && !suppressPopover) {
      this.didInit = true;
      dynamicImport('assets/external/poster.js');
      dynamicImport('assets/external/popover-v3.js').then(() => {
        Wistia.embedPopover(this.props.hashedId, this.getPopoverEmbedOptions()).then((p) => {
          this.popover = p;
        });
      });
    }
  }

  componentDidMount() {
    const { on } = this.props;

    this.maybeInitPopover();

    this.unbinds.push(
      on('colorchange', (color) => {
        this.setState({ color });

        if (this.popover && this.popover._poster) {
          this.popover._poster.updateEmbedOptions({ color });
        }
      }),
      on('subscribechange', (subscribe) => {
        if (this.popover && subscribe?.on) {
          this.popover.setOpeningIsDisabled(subscribe?.required);
        }
      }),
    );
  }

  componentDidUpdate() {
    this.maybeInitPopover();

    const { cardWidth, cardHeight, contentTypeLabel, posterRef, shouldShowVideoDescriptions } =
      this.props;

    const { isHoveringOrFocusing } = this.state;

    if (this.popover && this.popover._poster) {
      const width = cardWidth;
      const height = cardHeight;

      // Because we can calculate width and height without doing a DOM
      // operation (i.e. literally looking at the poster container's width and
      // height), we can save a _ton_ of time on renders by providing them
      // here.
      this.popover._poster.fit({ width, height });

      const popoverEmbedOptions = this.getPopoverEmbedOptions();
      const { playerColor, posterOptions } = popoverEmbedOptions;

      this.popover._poster.updateEmbedOptions(posterOptions);
      this.popover.setContentTypeLabel(contentTypeLabel);
      this.popover.setNavigation(popoverEmbedOptions.navigation);
      this.popover.setPlayerColor(playerColor);
      this.popover.setShouldShowDescription(shouldShowVideoDescriptions);
      this.popover.setShouldShowTranscript(this.getPopoverEmbedOptions().shouldShowTranscript);
      if (posterRef) {
        posterRef(this.popover._poster);
      }
      if (isHoveringOrFocusing) {
        this.popover._poster.showPlayButton();
      } else {
        this.popover._poster.hidePlayButton();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.unbinds.forEach((u) => u());
  }

  getPopoverEmbedOptions() {
    const {
      episodeId,
      galleryData,
      galleryEmbedOptions,
      getVideoEmbedOptions,
      headerFontFamily,
      index,
      subscribeIsRequired,
      title,
      type,
      viewerIsSubscribed,
    } = this.props;

    const fadeInDelay = index * INCREMENTAL_DELAY;

    // TODO: Once the data change has been out for a while, change this to
    // type === 'Video'. Until that's out, `type` may be undefined.
    const isVideo = type !== 'Audio';
    const videoFeatureOn = isVideo;

    const baseZIndex =
      galleryEmbedOptions.overlayZIndex != null ? galleryEmbedOptions.overlayZIndex : 10000;

    // we want to default to transcript being on in the popoverOverlay. So only an explicit `false`
    // should turn them off
    const shouldShowTranscript =
      galleryEmbedOptions.shouldShowTranscript == null
        ? true
        : galleryEmbedOptions.shouldShowTranscript;

    const embedOptions = assign(
      {
        channel: galleryData.hashedId,
        channelId: galleryData.numericId,
        channelTitle: title,
        container: this.popoverContainer,
        deliveryCdn: galleryEmbedOptions.deliveryCdn,
        episodeId,
        overlayZIndex: baseZIndex + 10,
        openingIsDisabled: subscribeIsRequired && !viewerIsSubscribed,
        playerColor: this.playerColor,
        podcastLinks: galleryData.podcastLinks,
        popoverContent: 'poster',
        posterOptions: {
          backgroundColor: 'transparent',
          color: this.playerColor,
          bpbOnHover: isVideo,
          channel: galleryData.hashedId,
          cursorPointer: 'everywhere',
          deliveryCdn: galleryEmbedOptions.deliveryCdn,
          embedHost: galleryEmbedOptions.embedHost,
          fadeInDelay,
          fadeInTime: 1000,
          translateTime: 1000,
          formatDuration,
          monitor: false,
          playButton: isVideo,
          playButtonTabIndex: -1,
          progressIndicator: galleryEmbedOptions.resumable !== false,
          showDuration: true,
          thumbnailFitStrategy: 'cover',
          videoFeature: videoFeatureOn ? 'deferred' : undefined,
        },
        shouldShowTitle: true,
        shouldShowTranscript,
        fontFamily: headerFontFamily,
      },
      getVideoEmbedOptions(),
    );

    // we should only put the resumable option on the popover
    // video if it is explicitly set
    if (galleryEmbedOptions.resumable != null) {
      embedOptions.resumable = galleryEmbedOptions.resumable;
    }

    return embedOptions;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return anyValuesChanged(this.props, nextProps) || anyValuesChanged(this.state, nextState);
  }

  fetchDescription() {
    const { afterBatchFetch, hashedId } = this.props;
    batchFetchData(hashedId, this.getPopoverEmbedOptions()).then(({ basic: { description } }) => {
      this.setState({ shortDescription: getShortDescription(unescapeHtml(description)) });
      if (afterBatchFetch) {
        afterBatchFetch();
      }
    });
  }

  prefetchScripts() {
    const { hashedId } = this.props;
    fetchMediaData(hashedId, this.getPopoverEmbedOptions()).then((mediaData) => {
      prefetchEngineAndPlugins(mediaData, mediaData.embedOptions);
    });
  }

  setGridMediaCardRef = (elem) => {
    const { elemRef } = this.props;
    if (elemRef) {
      elemRef(elem);
    }
    this.gridMediaCardRef = elem;
  };

  onClickGridMediaCard = (event) => {
    const { hashedId, onClickVideoCard } = this.props;
    event?.preventDefault();
    onClickVideoCard(hashedId, this.gridMediaCardRef);
  };

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.onClickGridMediaCard();
    }
  };

  onFocusOrMouseEnter = () => {
    if (this.state.shortDescription == null) {
      this.fetchDescription();
    }

    this.prefetchScripts();

    this.setState({
      isHoveringOrFocusing: true,
    });
  };

  onBlurOrMouseLeave = () => {
    this.setState({ isHoveringOrFocusing: false });
  };

  onTransitionEnd = () => {
    // This is important for a nuanced UI interaction, as the viewer moves their
    // into and out of the arrow click target area and the section it's in.
    if (this.state.isHoveringOrFocusing) {
      this.setState({
        isScaledUp: true,
      });
    } else {
      this.setState({
        isScaledUp: false,
      });
    }
  };

  popoverStyle() {
    return {
      height: '100%',
      position: 'relative',
      width: '100%',
    };
  }

  scaleWrapperStyle() {
    const { cardWidth } = this.props;
    const { isHoveringOrFocusing } = this.state;

    // When cards get really wide, scaling 20% may cause clipping. This code
    // detects when the clipping would happen and adjusts the scale to
    // guarantee it fits.
    let scale = 1.2;
    const numPxBigger = cardWidth * 1.2 - cardWidth;
    const numPxBiggerOnOneSide = numPxBigger / 2;
    if (numPxBiggerOnOneSide > marginWidth(this.props)) {
      const ratio = marginWidth(this.props) / numPxBiggerOnOneSide;
      scale = 1.2 * ratio;
    }

    return {
      transform: isHoveringOrFocusing ? `scale(${scale})` : 'scale(1.001)',
      // iOS has a rendering glitch that frequently causes the whole card to
      // disappear on focus if we have a transition property here. Disabling
      // it in that context for now.
      transition: detect.ios.version > 0 ? '' : `transform ${SCALE_TRANSITION_DURATION}ms`,
    };
  }

  hoverTranslateWrapperStyle() {
    const { isHoveringOrFocusing } = this.state;
    return {
      display: 'flex',
      flexDirection: 'column',
      transform: isHoveringOrFocusing ? 'translateY(-4%)' : 'translateY(0.001%)',
      transition: `transform ${HOVER_TRANSFORM_TRANSITION_DURATION}ms cubic-bezier(0, 0.8, 0, 1) ${HOVER_TRANSFORM_TRANSITION_DELAY}ms`,
    };
  }

  thumbnailWrapperStyle() {
    const { backgroundColor, cardHeight } = this.props;
    const { isHoveringOrFocusing } = this.state;
    return {
      boxShadow: isHoveringOrFocusing
        ? `0 0 10px 0 ${new Color(backgroundColor).alpha(0.6).toRgba()}`
        : '',
      flex: '0 0 auto',
      height: `${cardHeight}px`,
      overflow: 'hidden',
      position: 'relative',
    };
  }

  nameWrapperStyle() {
    return {
      backgroundColor: 'transparent',
      flex: '0 1 auto',
      paddingTop: '11px',
    };
  }

  textStyle() {
    const { foregroundColor, headerFontFamily } = this.props;
    return {
      color: `#${foregroundColor}`,
      fontFamily: headerFontFamily,
      fontWeight: 400,
      margin: 0,
    };
  }

  nameStyle() {
    return {
      ...this.textStyle(),
      display: '-webkit-box',
      fontSize: `${headerFontSizeGw(this.props, 1.4)}px`,
      lineHeight: '1.25em',
      overflow: 'hidden',
      position: 'relative',
      webkitBoxOrient: 'vertical',
      webkitLineClamp: 2,
      whiteSpace: 'normal',
    };
  }

  descriptionStyle() {
    const { isHoveringOrFocusing, shortDescription } = this.state;
    const { shouldShowVideoDescriptions } = this.props;
    return {
      ...this.textStyle(),
      display: shouldShowVideoDescriptions ? '-webkit-box' : 'none',
      fontSize: `${headerFontSizeGw(this.props, 1.12)}px`,
      opacity: isHoveringOrFocusing && shortDescription ? 1 : 0,
      overflow: 'hidden',
      transition: `opacity ${isHoveringOrFocusing ? DESCRIPTION_OPACITY_TRANSITION_DURATION : 0}ms`,
      webkitBoxOrient: 'vertical',
      webkitLineClamp: 3,
      whiteSpace: 'normal',
    };
  }

  descriptionAnchorStyle() {
    return {
      position: 'relative',
    };
  }

  descriptionWrapperStyle() {
    return {
      boxSizing: 'content-box',
      height: '3.5em',
      marginTop: '0.5em',
      paddingBottom: '1em',
    };
  }

  cardStyle() {
    const { cardWidth, style } = this.props;
    const { isHoveringOrFocusing } = this.state;

    return {
      cursor: 'pointer',
      display: 'block',
      flex: '0 0 auto',
      outline: 'none',
      position: 'relative',
      width: `${cardWidth}px`,
      zIndex: isHoveringOrFocusing ? 2 : '',
      ...style,
    };
  }

  render() {
    const { shortDescription } = this.state;

    return (
      <a
        class="w-video-card"
        href={this.videoUrl}
        onBlur={this.onBlurOrMouseLeave}
        onClick={this.onClickGridMediaCard}
        onFocus={this.onFocusOrMouseEnter}
        onKeyDown={this.onKeyDown}
        onMouseEnter={detect.hoverIsNatural ? this.onFocusOrMouseEnter : null}
        onMouseLeave={detect.hoverIsNatural ? this.onBlurOrMouseLeave : null}
        ref={this.setGridMediaCardRef}
        style={this.cardStyle()}
        tabIndex={'0'}
      >
        <div style={this.scaleWrapperStyle()}>
          <div style={this.hoverTranslateWrapperStyle()}>
            <div
              class="w-video-card__thumbnail-wrapper"
              onTransitionEnd={this.onTransitionEnd}
              style={this.thumbnailWrapperStyle()}
            >
              <RawHTMLStub
                class="wistia_popover"
                stubRef={(el) => (this.popoverContainer = el)}
                style={this.popoverStyle()}
              />
            </div>
            <div style={this.nameWrapperStyle()}>
              <h3 style={this.nameStyle()} title={this.unescapedName}>
                {this.unescapedName}
              </h3>
            </div>
            <div style={this.descriptionAnchorStyle()}>
              <div style={this.descriptionWrapperStyle()}>
                {shortDescription && (
                  <h4
                    class="w-video-card__description"
                    style={this.descriptionStyle()}
                    title={shortDescription}
                  >
                    {shortDescription}
                  </h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </a>
    );
  }
}

export default GridMediaCard;

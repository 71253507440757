import { cdnFastWistiaNetHost, forceValidFastWistiaHost } from 'utilities/hosts.js';
import { cacheMediaData } from 'utilities/remote-data-cache.ts';
import { mediaDataTransforms } from 'utilities/media-data-transforms.js';
import { speedDemonKey, speedDemonScriptExists, speedDemonScripts } from 'utilities/speedDemon.ts';
import { wlog } from 'utilities/wlog.js';
import { appHostname } from 'appHostname';
import { Wistia } from '../../wistia_namespace.ts';
import { FetchChannelDataTimeoutError } from './FetchChannelDataTimeoutError.ts';

export const galleryDataHost = (options = {}) => {
  if (options.embedHost) {
    return forceValidFastWistiaHost(options.embedHost);
  }
  return cdnFastWistiaNetHost();
};

const cacheOneMediaData = (mediaData, options) => {
  const { hashedId } = mediaData;
  mediaDataTransforms(mediaData, options);
  Wistia._mediaDataPromises[hashedId] = Promise.resolve({ media: mediaData });
  cacheMediaData(hashedId, mediaData);
};

const cacheMediaDataFromGalleryData = (galleryData, options) => {
  galleryData.series[0].sections.forEach((section) => {
    section.videos.forEach((video) => {
      if (video.mediaData) {
        mediaDataTransforms(video.mediaData, options);
        cacheOneMediaData(video.mediaData, options);
      }
    });
  });
  const requestedVideoMediaData = Object(galleryData.requestedVideoMediaData).mediaData;
  if (requestedVideoMediaData) {
    cacheOneMediaData(requestedVideoMediaData, options);
  }
};

export const fetchGallerySeedDataFromProject = (hashedId, options = {}) => {
  const url = options.locked
    ? new window.URL(
        `https://${appHostname(options.accountKey)}/channels/${hashedId}/logged_in_seed_data.json`,
      )
    : new window.URL(`https://${galleryDataHost(options)}/embed/channel/project/${hashedId}.json`);

  if (options.wmediaid) {
    url.searchParams.append('video_id', options.wmediaid);
  }
  if (options.locked) {
    url.searchParams.append('origin', `${window.location.protocol}//${window.location.hostname}`);
  }
  if (options.password) {
    url.searchParams.append('password', options.password);
  }

  wlog.info('galleryseeddata', url.href, options);
  return fetch(url, {
    credentials: options.locked ? 'include' : 'same-origin',
  })
    .then((resp) => {
      // If we get a 401 response here, this means we're in the context
      // of a password-protected channel that did not submit a valid passowrd in its request for data.
      if (resp.status === 401) {
        return resp.json().then((passwordProtectedChannelData) => {
          return Promise.resolve({
            error: 'Unauthorized',
            unauthorized: true,
            ...passwordProtectedChannelData,
          });
        });
      }
      if (resp.status === 403) {
        return resp.json().then((lockedChannelData) => {
          return Promise.resolve({
            error: lockedChannelData.error,
            locked: lockedChannelData.locked,
            ...lockedChannelData,
          });
        });
      }
      return resp.json();
    })
    .then((galleryData) => {
      if (!galleryData.error) {
        cacheMediaDataFromGalleryData(galleryData, options);
      }
      return galleryData;
    })
    .catch(() => {
      console.error(`Timed out fetching ${url.href}`);
      throw new FetchChannelDataTimeoutError(`Timed out fetching ${url.href}`);
    })
    .then((galleryData) => {
      // TODO - when flag_hard_wall_for_locked_channels is released, we can remove the
      // check for galleryData.showHardWall
      if (galleryData.unauthorized || (galleryData.locked && galleryData.showHardWall)) {
        return galleryData;
      }
      if (galleryData?.error) {
        const err = new Error(galleryData.error);
        err.locked = galleryData.locked;
        throw err;
      }
      return galleryData;
    });
};

export const fetchGalleryDataFromSpeedDemonScript = (hashedId, options) => {
  return new Promise((resolve, reject) => {
    if (!speedDemonScriptExists(hashedId)) {
      // If there's no speed demon script (or it fails), say so. This lets us
      // fall back to the old fashioned XHR fetch downstream.
      reject(new Error(`No speed demon script for ${hashedId}`));
      return;
    }

    // If we see the variable for it already, no need to wait on scripts.
    if (window[speedDemonKey(hashedId)]) {
      cacheMediaDataFromGalleryData(window[speedDemonKey(hashedId)], options);
      resolve(window[speedDemonKey(hashedId)]);
      return;
    }

    const scripts = speedDemonScripts(hashedId);

    const key = speedDemonKey(hashedId);
    scripts.forEach((script) => {
      if (window[key]) {
        cacheMediaDataFromGalleryData(window[key], options);
        resolve(window[key]);
        return;
      }

      script.addEventListener('load', () => {
        if (window[key]) {
          cacheMediaDataFromGalleryData(window[key], options);
          resolve(window[key]);
        }
      });
      script.addEventListener('error', () => {
        reject(new Error(`Speed demon: script load error for ${script.src}`));
      });
    });

    setTimeout(() => {
      resolve(undefined);
    }, 30000);
  });
};

export default fetchGallerySeedDataFromProject;

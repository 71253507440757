export const HARD_WALL_STYLES = `
  :host {
    all: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    container: wistiaHardWall / size;
  }

  .hard-wall-container {
    background: linear-gradient(var(--wistia-hard-wall-background-gradient-stop-one), var(--wistia-hard-wall-background-gradient-stop-two));
    width: 100%;
    height: 100%;
    padding: 32px;
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    gap: 2rem;
    color: var(--wistia-hard-wall-form-text-color);
    overflow: scroll;
    border-radius: var(--wistia-hard-wall-container-border-radius);
  }

  .hard-wall-container.has-inline-logo {
    display: flex;
  }

  .logo-container {
    flex: 0 1 auto;
    overflow: hidden;
  }

  .logo-container img {
    max-width: 200px;
    max-height: 100px;
    display: block;
    margin: auto;
  }

  .login-prompt-container,
  .inline-message-container {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: var(--wistia-hard-wall-form-background-color);
    border: 1px solid var(--wistia-hard-wall-form-border-color);
    padding: 24px;
    border-radius: var(--wistia-hard-wall-form-border-radius);
    color: var(--wistia-hard-wall-form-text-color);
    gap: 24px;
  }

  .form-container {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: var(--wistia-hard-wall-form-background-color);
    border: 1px solid var(--wistia-hard-wall-form-border-color);
    padding: 24px;
    border-radius: var(--wistia-hard-wall-form-border-radius);
    max-width: 550px;
  }

  .form-logo-container {
    flex: 0 1 auto;
    overflow: hidden;
    align-self: center;
  }

  .form-logo-container img {
    max-width: 100%;
    max-height: 32px;
  }

  form {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
  }

  input {
    width: 100%;
    margin: 1rem 0;
    height: 36px;
    border-radius: var(--wistia-hard-wall-button-border-radius);
    background-color: var(--wistia-hard-wall-form-input-background-color);
    color: var(--wistia-hard-wall-form-input-text-color);
    border-color: var(--wistia-hard-wall-form-input-border-color);
    border-style: solid;
  }

  .input-error-message {
    color: var(--wistia-hard-wall-form-error-text-color);
    margin-bottom: 1em;
  }

  button[type="submit"],
  .login-prompt-link {
    background: var(--wistia-hard-wall-form-button-background-color);
    color: var(--wistia-hard-wall-form-button-text-color);
    border: 0;
    border-radius: var(--wistia-hard-wall-button-border-radius);
    padding: 0.75rem;
    cursor: pointer;
    text-decoration: none;
    text-align: center;

    &:hover:not([disabled]) {
      background: var(--wistia-hard-wall-form-button-hover-background-color);
      color: var(--wistia-hard-wall-form-button-hover-text-color);
    }
  }

  @container wistiaHardWall (width < 768px) {
    .hard-wall-container {
      padding: 16px;
      gap: 16px;
    }
      
    .form-container form {
      padding: 16px;
      font-size: 0.8em;
    }
  }

  @container wistiaHardWall (width <= 480px) {
    .hard-wall-container {
      padding: 8px;
    }

    .form-container {
      font-size: 0.75em;
      padding: 8px;
    }

    .form-container form {
      padding: 8px;
    }
  }
`;

import { Nilable } from '@wistia/type-guards';
import { h, JSX } from 'preact';
import { HardWall } from './HardWall.tsx';
import { PasswordForm } from './PasswordForm.tsx';

type HardWallWithPasswordFormProps = {
  backgroundColor: string;
  channelTitle: string;
  containerBorderRadius?: Nilable<number>;
  logoUrl?: Nilable<string>;
  onSubmitPassword: (password?: string) => Promise<null>;
  playerColor: string;
};

export const HardWallWithPasswordForm = ({
  backgroundColor,
  channelTitle,
  containerBorderRadius,
  logoUrl,
  onSubmitPassword,
  playerColor,
}: HardWallWithPasswordFormProps): JSX.Element => {
  return (
    <HardWall
      backgroundColor={backgroundColor}
      containerBorderRadius={containerBorderRadius}
      playerColor={playerColor}
    >
      <PasswordForm
        onSubmitPassword={onSubmitPassword}
        channelTitle={channelTitle}
        logoUrl={logoUrl}
      />
    </HardWall>
  );
};

import { h, JSX } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { isNonEmptyString, Nilable } from '@wistia/type-guards';
import { InvalidPasswordError } from './InvalidPasswordError.ts';
import {
  defineTranslations,
  getDefaultTranslation,
  getTranslation,
  matchingLanguages,
} from '../../translations.js';

defineTranslations('en-US', {
  ENTER_PASSWORD_PHRASE: 'Please enter your password.',
  INCORRECT_PASSWORD: 'Incorrect Password',
  PASSWORD: 'Password',
  SUBMIT: 'Submit', // TODO - needs translations
});

export const PasswordForm = ({
  channelTitle,
  logoUrl,
  onSubmitPassword,
}: {
  channelTitle: string;
  logoUrl?: Nilable<string>;
  onSubmitPassword: (password?: string) => Promise<null>;
}): JSX.Element | null => {
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [shouldShowError, setShouldShowError] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isWaitingForTranslations, setIsWaitingForTranslations] = useState(true);

  // First fetch the translations on first render
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    getDefaultTranslation().then(() => {
      setIsWaitingForTranslations(false);
    });
  }, []);

  const translations = useMemo(() => {
    // Since the translations module is not yet in typescript, we need to cast this return value
    const languageCode = (matchingLanguages() as (string | undefined)[]).shift() ?? 'en';
    return {
      /* eslint-disable @typescript-eslint/no-unsafe-assignment */
      ENTER_PASSWORD_PHRASE: getTranslation(languageCode, 'ENTER_PASSWORD_PHRASE'),
      INCORRECT_PASSWORD: getTranslation(languageCode, 'INCORRECT_PASSWORD'),
      PASSWORD: getTranslation(languageCode, 'PASSWORD'),
      SUBMIT: getTranslation(languageCode, 'SUBMIT'),
      /* eslint-enable @typescript-eslint/no-unsafe-assignment */
    };
  }, [isWaitingForTranslations]);

  if (isWaitingForTranslations) {
    return null;
  }

  return (
    <div className="form-container">
      {isNonEmptyString(logoUrl) && (
        <div className="form-logo-container">
          <img src={logoUrl} alt="logo" />
        </div>
      )}
      <form
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
          // Don't allow the form to be submitted again until we've handled the response
          // from any previous submission
          if (isSubmitDisabled) {
            return;
          }
          setIsSubmitDisabled(true);
          // TODO - filter out characters that are not allowed to be used in passwords
          onSubmitPassword(passwordInputRef.current?.value)
            .then(() => {
              // We did it!
              // Should we do some tracking here?
            })
            .catch((reason: unknown) => {
              if (reason instanceof InvalidPasswordError) {
                setShouldShowError(true);
              }
            })
            .finally(() => {
              setIsSubmitDisabled(false);
            });
        }}
      >
        <h1>{translations.ENTER_PASSWORD_PHRASE}</h1>
        <label>
          {translations.PASSWORD}
          <input
            data-testid="password-input"
            type="password"
            onInput={() => {
              setShouldShowError(false);
              setIsSubmitDisabled(!isNonEmptyString(passwordInputRef.current?.value));
            }}
            ref={passwordInputRef}
            aria-label={`Password for ${channelTitle}`}
          />
        </label>
        {shouldShowError && (
          <span className="input-error-message" data-testid="error-text">
            {translations.INCORRECT_PASSWORD}
          </span>
        )}
        <button data-testid="submit-button" type="submit" disabled={isSubmitDisabled}>
          {translations.SUBMIT}
        </button>
      </form>
    </div>
  );
};

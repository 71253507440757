import { mediaDataHost } from 'utilities/hosts.js';
import { cacheMediaData } from 'utilities/remote-data-cache.ts';
import { assign, cast } from 'utilities/obj.js';
import { Url } from 'utilities/url.js';
import { snakeCase } from 'utilities/core.js';
import { mediaDataTransforms } from 'utilities/media-data-transforms.js';
import { Wistia } from '../wistia_namespace.ts';

// set up the batchFetchCache on the W namespace
if (!Wistia.batchFetchCache) {
  Wistia.batchFetchCache = {};
}

if (!Wistia.batchFetchKeys) {
  Wistia.batchFetchKeys = {};
}

// debouncing timeout
let processBatchTimeout;

const addHashedIdToQueue = (cacheKey) => {
  Wistia.batchFetchKeys[cacheKey] = {
    processed: false,
  };
};

// build up the media data url to the batch_media_data endpoint
const batchMediaDataUrl = (queryParams, embedHost) => {
  const url = new Url(`https://${embedHost}/embed/batch_media_data`);
  url.params = queryParams;
  return url.absolute();
};

// creates param obj like
// {
//   basic: true,
//   distillery: true,
//   media_hashed_ids: 'abc-def-ghi'
// }
const buildQueryParams = (bucket, hashedIds, channelId, channelPassword) => {
  const queryParams = {};
  bucket.split('-').forEach((param) => {
    queryParams[snakeCase(param)] = true;
  });
  queryParams.media_hashed_ids = hashedIds.sort().join('-');
  if (channelId) {
    queryParams.channel_id = channelId;
  }
  if (channelPassword) {
    queryParams.channel_password = channelPassword;
  }

  return queryParams;
};

// mostly used in specs, but could be useful elsewhere, too
export const bustRequestCache = () => {
  for (let key in Wistia.batchFetchCache) {
    delete Wistia.batchFetchCache[key];
  }

  Wistia.batchFetchKeys = {};
  Wistia._mediaDataCache = {};
};

const cacheMediaRequest = (cacheKey, mediaPromise) => {
  Wistia.batchFetchCache[cacheKey] = assign(Wistia.batchFetchCache[cacheKey] || {}, mediaPromise);
  if (mediaPromise.mediaData) {
    mediaPromise.mediaData.promise.then((data) => {
      const { mediaData } = data;
      cacheMediaData(mediaData.hashedId, mediaData);
    });
  }
};

// smoosh together a hashedId and its embedHost to create a key like:
// 'abc-{"embedHost":"fast.wistia.com"}'
const createCacheKey = (hashedId, options = {}) => {
  // The options here have an impact on the contents of the cache.
  const meaningfulOptions = {
    // This impacts media data transforms after we fetch the data
    deliveryCdn: options.deliveryCdn,

    // This impacts the source of the data (e.g. local, prod, staging, etc.)
    embedHost: mediaDataHost(options),

    // Media can live in multiple channels, so we need to know
    // which channel's episode data to return
    channelId: options.channelId,

    // If the channel is password-protected, media will need to know
    // the password to fetch the data
    channelPassword: options.channelPassword ?? options.plugin?.passwordProtectedChannel?.password,
  };
  return `${hashedId}-${JSON.stringify(meaningfulOptions)}`;
};

export const batchFetchData = function (hashedId, options, buckets = { basic: true }) {
  if (!options) {
    options = {};
  }

  const cacheKey = createCacheKey(hashedId, options);

  // if we already have Wistia.batchFetchCache for the requested buckets,
  // it's safe to bail and return those promises
  if (Wistia.batchFetchCache[cacheKey]) {
    const bucketsWithoutPromises = Object.keys(buckets).filter(
      (bucket) => !Wistia.batchFetchCache[cacheKey][bucket],
    );
    if (bucketsWithoutPromises.length === 0) {
      return responseForBuckets(cacheKey, buckets);
    }
  }

  const mediaPromise = createMediaPromises(cacheKey, buckets);

  cacheMediaRequest(cacheKey, mediaPromise);
  addHashedIdToQueue(cacheKey);
  clearTimeout(processBatchTimeout);

  processBatchTimeout = setTimeout(() => {
    processBatch();
  }, 300);

  return responseForBuckets(cacheKey, buckets);
};

// create a mediaPromise object looking like:
// {
//   basic: { resolve, reject, Promise },
//   distillery: { resolve, reject, Promise },
// }
const createMediaPromises = (cacheKey, buckets = { basic: true }) => {
  const existingRequest = Wistia.batchFetchCache[cacheKey] || {};
  const queuedRequest = assign({}, existingRequest);

  // create a promise for each requested bucket if it doesn't exist
  // storing it's resolve/reject to called later elsewhere
  for (let view in buckets) {
    if (!queuedRequest[view]) {
      let queuedResolve;
      let queuedReject;
      const promise = new Promise((resolve, reject) => {
        queuedResolve = resolve;
        queuedReject = reject;
      });

      queuedRequest[view] = {
        resolve: queuedResolve,
        reject: queuedReject,
        promise,
      };
    }
  }

  return queuedRequest;
};

// combine multiple network responses into a single obj
const formatMediaDataResponse = (responseData) => {
  let formattedData = {};

  responseData.forEach((data) => {
    formattedData = assign({}, formattedData, data);
  });

  return formattedData;
};

export const formatAndCacheData = function (hashedId, options = {}, data = {}) {
  if (arguments.length === 2) {
    // This is a translation layer between the old call-site format (which
    // didn't include `options`) and the new one (which does). Once this has
    // been live for a few hours, we can remove this.
    data = options;
    options = { embedHost: data.embedHost };
  }

  const cacheKey = createCacheKey(hashedId, options);
  const response = {};

  for (const bucket in data) {
    response[bucket] = {
      promise: Promise.resolve({ [bucket]: data[bucket] }),
    };
  }

  cacheMediaRequest(cacheKey, response);
};

const getUnprocessedRequests = () => {
  return Object.keys(Wistia.batchFetchKeys).filter((cacheKey) => {
    return Wistia.batchFetchKeys[cacheKey].processed === false;
  });
};

// returns obj like:
// {
//   basic: ['abc', 'jkl', 'mno'],
//   basic-distillery: ['def', ghi],
// }
const hashedIdsByRequestBucket = (hashedIds, options) => {
  const hashedIdsByBucket = {};

  hashedIds.forEach((hashedId) => {
    const cacheKey = createCacheKey(hashedId, options);
    const viewsToFetch = Object.keys(Wistia.batchFetchCache[cacheKey]);
    const bucketKey = viewsToFetch.sort().join('-');
    if (bucketKey) {
      if (!hashedIdsByBucket[bucketKey]) {
        hashedIdsByBucket[bucketKey] = [];
      }
      hashedIdsByBucket[bucketKey].push(hashedId);
    }
  });

  return hashedIdsByBucket;
};

// recursively go through the batchFetchKeys and process them in chunks of 100
// important to note that this will only process the batchFetchKeys that were
// present when this method was called
const processBatch = () => {
  const queuedIds = getUnprocessedRequests();

  const processChunk = () => {
    const chunkedHashedIds = queuedIds.splice(0, 100);

    processHashedIds(chunkedHashedIds).then(() => {
      if (queuedIds.length > 0) {
        processChunk();
      }
    });
  };

  processChunk();
};

const processHashedIds = (hashedIdsInChunk) => {
  // split all the hashedIds to process into different embedHosts
  const hashedIdsByOptions = splitCacheKeysByOptions(hashedIdsInChunk);

  const promises = [];

  // go through each embedHost type making Wistia.batchFetchCache for each one
  for (const optionsKey in hashedIdsByOptions) {
    const hashedIds = hashedIdsByOptions[optionsKey];
    const parsedOptionsKey = JSON.parse(optionsKey);

    // bucket the hashedIds by bucket combination
    const hashedIdsByBucket = hashedIdsByRequestBucket(hashedIds, parsedOptionsKey);

    // One request for each param bucket key combination
    Object.keys(hashedIdsByBucket).forEach((bucketKey) => {
      const params = buildQueryParams(
        bucketKey,
        hashedIdsByBucket[bucketKey],
        parsedOptionsKey.channelId,
        parsedOptionsKey.channelPassword,
      );
      const url = batchMediaDataUrl(params, parsedOptionsKey.embedHost);

      // Do the request.
      const requestPromise = fetch(url);
      promises.push(requestPromise);

      requestPromise
        .then((resp) => resp.json())
        .then((response) => {
          const medias = response.medias;

          // resolve corresponding bucket promises per hashedid in the response
          for (let hashedId in medias) {
            bucketKey.split('-').forEach((bucket) => {
              const media = medias[hashedId];

              if (media.error) {
                rejectRequestByBucket(hashedId, parsedOptionsKey, media, bucket);
              } else {
                resolveRequestByBucket(hashedId, parsedOptionsKey, media, bucket);
              }
            });
          }
        });
    });
  }

  return Promise.all(promises);
};

// we want to make sure _all_ buckets have resolved before removing a cacheKey from the queue.
// Prevents against the possible scenario of:
// - Hashed Id 'abc' is in the queue.
// - Request for bucket A is fired
// - Bucket B for 'abc' is asked for but the request is debounced, 'abc' is still in the queue at this point
// - A returns and resolves, but B hasn't fired so we don't want to remove the cacheKey from the Queue
const possiblyRemoveHashedIdFromQueue = (cacheKey) => {
  if (Wistia.batchFetchCache[cacheKey]) {
    const cache = Wistia.batchFetchCache[cacheKey];
    const promises = Object.keys(cache)
      .map((k) => cache[k].promise)
      .filter(Boolean);

    Promise.all(promises).then(() => {
      if (Wistia.batchFetchKeys[cacheKey]) {
        Wistia.batchFetchKeys[cacheKey].processed = true;
      }
    });
  }
};

const promiseForBuckets = (cacheKey, buckets) => {
  const promises = [];
  for (const bucket in buckets) {
    if (Object(Wistia.batchFetchCache[cacheKey])[bucket]) {
      promises.push(Wistia.batchFetchCache[cacheKey][bucket].promise);
    }
  }

  return Promise.all(promises);
};

const responseForBuckets = (cacheKey, buckets) => {
  return promiseForBuckets(cacheKey, buckets).then((responseData) => {
    possiblyRemoveHashedIdFromQueue(cacheKey);
    return formatMediaDataResponse(responseData);
  });
};

const rejectRequestByBucket = (hashedId, options, media, bucketType) => {
  const cacheKey = createCacheKey(hashedId, options);
  // ensure the request exists
  if (Wistia.batchFetchCache[cacheKey]) {
    Wistia.batchFetchCache[cacheKey][bucketType].reject(media);
  }
};

// resolve a given hashedId's bucket type promise
const resolveRequestByBucket = (hashedId, options, media, bucketType) => {
  const cacheKey = createCacheKey(hashedId, options);

  // for mediaData, we want to apply transforms here, so it doesn't have to
  // be worried about downstream
  if (bucketType === 'mediaData') {
    mediaDataTransforms(media[bucketType], options);
  }

  // embedOptions come back with string values where we actually want booleans.
  if (bucketType === 'embedOptions') {
    cast(media[bucketType]);
  }

  // ensure the resolve function exists for the bucket type.
  // e.g. We allow the batchFetchCache to be "seeded" with some data
  // to avoid extra requests where possible, and when that happens
  // the resolve function is not placed on the cache
  if (Object(Wistia.batchFetchCache[cacheKey])[bucketType]?.resolve) {
    Wistia.batchFetchCache[cacheKey][bucketType].resolve({ [bucketType]: media[bucketType] });
  }
};

// returns an object looking like:
// {
//   '{"embedHost":"fast.wistia.com"}': ['abc', 'def'],
//   '{"embedHost":"fast.wistia.io"}': ['ghi' 'jkl'],
//   '{"embedHost":"fast.wistia.com","deliveryCdn":"fastly"}': ['ghi' 'jkl'],
// }
const splitCacheKeysByOptions = (cacheKeys) => {
  const optionsPermutations = {};

  cacheKeys.forEach((cacheKey) => {
    const [_wholeString, hashedId, optionsStr] = cacheKey.match(/([^-]+)-(.*)/);

    if (!optionsPermutations[optionsStr]) {
      optionsPermutations[optionsStr] = [];
    }

    optionsPermutations[optionsStr].push(hashedId);
  });

  return optionsPermutations;
};

import { isNil, Nilable } from '@wistia/type-guards';
import { camelCaseToKebabCase } from '../../../../utilities/camelCaseToKebabCase.ts';

type HardWallBorderRadius = {
  buttonBorderRadius: number;
  containerBorderRadius: number;
  formBorderRadius: number;
};

const BUTTON_BORDER_RADIUS_RATIO = 0.666667;
const FORM_BORDER_RADIUS_RATIO = 0.75;

export const getHardWallBorderRadiusSizes = (
  containerBorderRadius?: Nilable<number>,
): HardWallBorderRadius => {
  // Channel embeds do not currently support a border radius embed option,
  // so we should provide our default values here.
  if (isNil(containerBorderRadius)) {
    return {
      containerBorderRadius: 0,
      formBorderRadius: 20,
      buttonBorderRadius: 9,
    };
  }
  return {
    containerBorderRadius,
    formBorderRadius: containerBorderRadius * FORM_BORDER_RADIUS_RATIO,
    buttonBorderRadius:
      containerBorderRadius * FORM_BORDER_RADIUS_RATIO * BUTTON_BORDER_RADIUS_RATIO,
  };
};

export const getHardWallBorderRadiusAsCssVars = (
  containerBorderRadius?: Nilable<number>,
): string => {
  const hardWallBorderRadiusValues = getHardWallBorderRadiusSizes(containerBorderRadius);
  return Object.entries(hardWallBorderRadiusValues)
    .map(([key, value]) => {
      const variableName = `--wistia-hard-wall-${camelCaseToKebabCase(key)}`;
      return `${variableName}:${value}px`;
    })
    .join(';\n');
};

import { Color } from 'utilities/color.js';
import { anyValuesChanged } from 'utilities/any-values-changed.js';
import { cachedDetect } from 'utilities/detect.js';
import { h, render, Component } from 'preact';
import { isMouseDownRecently } from 'utilities/isMouseDown.js';
import { interFontFamily } from 'utilities/interFontFamily.js';
import { isMobileWidth, shouldUseNarrowLayout } from './galleryMath.js';
import ScaleableWrapper from './ScaleableWrapper.jsx';
import headerFontSizeGw from './headerFontSizeGw.js';
import { RoundedSmallPlayButton } from '../shared/RoundedSmallPlayButton.tsx';

const detect = cachedDetect();
class BigButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: props.color,
      isHovering: false,
      isKeyboardFocused: false,
    };
    this.unbinds = [];
  }

  buttonStyle() {
    const backgroundColor = new Color(this.state.color);
    const { galleryContext } = this.props;
    const { isHovering, isKeyboardFocused } = this.state;
    const propsWithGalleryContext = { ...this.props, ...galleryContext };
    if (isHovering) {
      backgroundColor.lighten(30);
    }

    return {
      background: backgroundColor.toRgba(),
      border: 0,
      borderRadius: '4px',
      boxShadow: this.state.isKeyboardFocused ? '0 0 0 2px #fff inset' : 'none',
      color: '#fff',
      cursor: 'pointer',
      flex:
        shouldUseNarrowLayout(galleryContext) || isMobileWidth(galleryContext)
          ? '1 0 auto'
          : '0 0 auto',
      fontFamily: interFontFamily,
      fontSize: headerFontSizeGw(
        propsWithGalleryContext,
        shouldUseNarrowLayout(galleryContext) ? 4 : 1.4,
      ),
      lineHeight: '1.2em',
      outline: 'none',
      padding: shouldUseNarrowLayout(galleryContext)
        ? `${headerFontSizeGw(this.props, 3)}px`
        : `${headerFontSizeGw(propsWithGalleryContext, 1.2)}px ${headerFontSizeGw(
            propsWithGalleryContext,
            2,
          )}px ${headerFontSizeGw(propsWithGalleryContext, 1.3)}px ${headerFontSizeGw(
            propsWithGalleryContext,
            2,
          )}px`,
      transform: isHovering || isKeyboardFocused ? 'scale(1.1)' : 'scale(1.001)',
      transformOrigin: 'bottom center',
      transition: 'transform 200ms cubic-bezier(0.15, 0.51, 0.25, 1)',
      whiteSpace: 'nowrap',
      width: 'auto',
    };
  }

  componentDidMount() {
    const { on } = this.props;
    if (on) {
      this.unbinds.push(
        on('colorchange', (color) => {
          this.setState({ color });
        }),
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.color !== this.state.color) {
      this.setState({ color: nextProps.color });
    }
  }

  componentWillUnmount() {
    this.unbinds.forEach((u) => u());
  }

  onBlur = () => {
    this.setState({ isKeyboardFocused: false });
    const { onBlurButton } = this.props;
    if (onBlurButton) {
      onBlurButton();
    }
  };

  onClickButton = () => {
    if (this.props.onClickButton) {
      this.props.onClickButton();
    }
  };

  onFocus = () => {
    this.setState({
      isKeyboardFocused: !isMouseDownRecently(),
    });

    const { onFocusButton } = this.props;
    if (onFocusButton && !isMouseDownRecently()) {
      onFocusButton();
    }
  };

  onMouseEnter = detect.hoverIsNatural
    ? () => {
        this.setState({ isHovering: true });
        const { onMouseEnterButton } = this.props;
        if (onMouseEnterButton) {
          onMouseEnterButton();
        }
      }
    : undefined;

  onMouseLeave = detect.hoverIsNatural
    ? () => {
        this.setState({ isHovering: false });
        const { onMouseLeaveButton } = this.props;
        if (onMouseLeaveButton) {
          onMouseLeaveButton();
        }
      }
    : undefined;

  playButtonStyle() {
    const { galleryContext } = this.props;
    const propsWithGalleryContext = { ...this.props, ...galleryContext };

    return {
      boxSizing: 'content-box',
      display: 'inline-block',
      height: headerFontSizeGw(
        propsWithGalleryContext,
        shouldUseNarrowLayout(galleryContext) ? 4 : 1.4,
      ),
      marginLeft: headerFontSizeGw(
        propsWithGalleryContext,
        shouldUseNarrowLayout(galleryContext) ? -0.4 : -0.1,
        0,
      ),
      padding: `0 ${headerFontSizeGw(
        propsWithGalleryContext,
        shouldUseNarrowLayout(galleryContext) ? 2.29 : 0.8,
      )}px 0 0 `,
      position: 'relative',
      top: headerFontSizeGw(
        propsWithGalleryContext,
        shouldUseNarrowLayout(galleryContext) ? 0.4 : 0.1,
        0,
      ),
      transform: 'scale(0.9)',
      transformOrigin: '38% center',
      verticalAlign: 'top',
      width: headerFontSizeGw(
        propsWithGalleryContext,
        shouldUseNarrowLayout(galleryContext) ? 2 : 0.9,
        0,
      ),
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return anyValuesChanged(this.props, nextProps) || anyValuesChanged(this.state, nextState);
  }

  textStyle() {
    return {
      display: 'inline-block',
      verticalAlign: 'top',
    };
  }

  render() {
    const { alignment, buttonClass, galleryContext, setButtonRef } = this.props;
    return (
      <ScaleableWrapper alignment={alignment} galleryContext={galleryContext}>
        <button
          class={`w-channel-big-button${buttonClass ? ` ${buttonClass}` : ''}`}
          onBlur={this.onBlur}
          onClick={this.onClickButton}
          onFocus={this.onFocus}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          ref={setButtonRef}
          style={this.buttonStyle()}
          type="button"
        >
          {!this.props.removePlayButton && (
            <span style={this.playButtonStyle()}>
              <RoundedSmallPlayButton svgStyle={{ transform: 'scale(4.5)' }} />
            </span>
          )}
          <span style={this.textStyle()}>{this.props.children}</span>
        </button>
      </ScaleableWrapper>
    );
  }
}

export default BigButton;
